import routesArray from './routes';
import { Switch, Route } from 'react-router-dom';
import LoadingCover from '../../Components/LoadingCover';
import { FC, LazyExoticComponent, Suspense, Fragment } from 'react';

export type Path = {
	guard?: any;
	exact: boolean;
	path: string[];
	component: LazyExoticComponent<() => JSX.Element> | (() => JSX.Element) | FC;
};

export type RouteRendererProps = {
	base?: string;
	routes?: Path[];
	fallback?: () => JSX.Element;
};

const RouteRenderer: FC<RouteRendererProps> = ({ fallback, base = '', routes = routesArray }) => {
	return (
		<Suspense fallback={fallback?.() || <LoadingCover />}>
			<Switch>
				{routes.map((route, i) =>
					route.path.map(path => {
						let PathGuard = route.guard || Fragment;
						return (
							<Route key={i} path={`${base}${path}`} exact={route.exact}>
								<PathGuard>
									<route.component />
								</PathGuard>
							</Route>
						);
					})
				)}
			</Switch>
		</Suspense>
	);
};

export default RouteRenderer;
