const BASE = {
	BASE_URL: '/',
	APP_NAME: "Rumi's Kitchen Reservation",
	RUMIS_KITCHEN_URL: 'http://www.rumiskitchen.com/',
};

const PRODUCTION = {
	SERVER: 'https://back.rumishq.com',
};

const DEVELOPMENT = {
	SERVER: 'https://back.dev.rumishq.com',
};

const LOCAL = {
	// SERVER: 'https://back.rumishq.com',
	SERVER: 'https://back.dev.rumishq.com',
};

//? --------------------- Config selection ----------------------------------------

export type CONFIG_TYPE = typeof BASE & typeof PRODUCTION & typeof DEVELOPMENT & typeof LOCAL;

const CONFIG_BY_MODE: CONFIG_TYPE = {
	...(({ LOCAL, PRODUCTION, DEVELOPMENT } as any)[window.MODE as any] || {}),
};

export const CONFIG = {
	...BASE,
	...CONFIG_BY_MODE,
	API_SERVER: CONFIG_BY_MODE?.SERVER + '/api',
};

window.CONFIG = CONFIG;
