import { createReducer } from '@reduxjs/toolkit';
import { setData } from '../actions/DataActions';

const initState: InitStateType = { is_loading: false };

const LocalStorageReducer = createReducer(initState, {
	//*-------------------------- Data reducer -----------------------------

	[setData.type]: (state: InitStateType, { payload }) => ({ ...(state || {}), data: payload }),

	//*----------------------------------------------------------------------
});

type InitStateType = { data?: any; is_loading: boolean };

export default LocalStorageReducer;
