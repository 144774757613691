import { IconNames } from '../Assets/fontawesome/fa.names';
import svgBundle from '../Assets/fontawesome/fa.bundle.svg';
import { FC, useState, useEffect, MouseEventHandler } from 'react';

const dynamicSvg = (name: string) => () => import(`../Assets/fontawesome/svg/${name}.fa.svg`);
let importSvg: { [key: string]: () => Promise<typeof import('*.svg')> } = {
	t: dynamicSvg('thin'),
	l: dynamicSvg('light'),
	r: dynamicSvg('regular'),
	s: dynamicSvg('solid'),
	d: dynamicSvg('duotone'),
	b: dynamicSvg('brands'),
};

type FaIconProps = {
	fa: IconNames;
	isStatic?: boolean;
	className?: string;
	onClick?: MouseEventHandler<SVGSVGElement>;
};

const FaIcon: FC<FaIconProps> = ({ fa, className = '', isStatic, onClick }) => {
	const [svgBase, setSvgBase] = useState('');
	const [svgTag, setSvgTag] = useState<string>();

	useEffect(() => {
		if (svgTag === '#' + fa) return;
		setSvgTag('#' + fa);
		if (window.MODE === 'LOCAL') importSvg[fa[0]]().then(s => setSvgBase(s.default));
		else setSvgBase(svgBundle);
	}, [fa]);

	const IconElement = (
		<svg onClick={onClick} className={`fa-icon ${fa} ${className}`} viewBox='0 0 10 10'>
			<use xlinkHref={svgBase + svgTag} />
		</svg>
	);

	return IconElement;
};

export default FaIcon;
